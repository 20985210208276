import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterCategory from '@/components/layout/NewRegisterCategory/newRegisterCategory.vue'
import Category from '@/shared/models/category'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarCategoria',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterCategory.name]: NewRegisterCategory
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Categoria',
    category: new Category(),
    isNew: false,
    optionsCategoryLocalTraining: [],
    optionsCategoryModality: [],
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Categorias',
            href: this.$router.resolve({ name: 'Category' }).href
          },
          {
            text: 'Editar Categoria',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
    this.getAllModalityByLocalTraining()
  },

  methods: {
    getById() {
      loading.push()
      CategoryRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.category = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar categoria', 'ERRO')
        })
    },

    getAllModalityByLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
            this.modalityLocalTraining = res.data.data
            this.onSelectModality()
            this.onSelectLocalTraining()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar modalidades por local de treinamento', 'ERRO')
        })
    },

    onSelectLocalTraining (localTrainingId) {
      let list = this.modalityLocalTraining
      
      if (localTrainingId) {
        const type = this.optionsCategoryLocalTraining.filter(localTraining => localTraining.id === localTrainingId)

        if (type.length > 0) {
          this.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(modalityLocal => modalityLocal.localTrainingId === type[0].id)
        }
      }

      this.optionsCategoryModality = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.optionsCategoryModality.filter(m => m.id === modality.id).length === 0) {
          this.optionsCategoryModality.push(modality)
        }
      }
    },

    onSelectModality (modalityId) {
      let list = this.modalityLocalTraining
     
      if (modalityId) {
        let type = this.optionsCategoryModality.filter(s => s.id === modalityId)

        if (type.length > 0) {
          this.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.optionsCategoryLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.optionsCategoryLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.optionsCategoryLocalTraining.push(local)
        }
      }
    },

    update(category) {
      loading.push()
      CategoryRepository.Update(category)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Categoria editada com sucesso', 'EDITAR CATEGORIA')
          Promise.resolve()
          return this.$router.replace({ name: 'Category' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar categoria', 'ERRO')
        })
    }
  }
}
